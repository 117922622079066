(function () {
    $Application.run(function () {
        /* Manage GDS */
        this.gds();
        
        /* Manage pseudo-CRUD delete */
        this.manageDeleteActios('action-delete');
        
        /* Manage menu hamburger */
        $("#nav-burger").on('click', function () {
            var toggleClass  = function (cssElement, cssClass) {
                var element = $(cssElement);
                
                if (element.hasClass(cssClass)) {
                    element.removeClass(cssClass);
                } else {
                    element.addClass(cssClass);
                }
            };
            
            toggleClass('#nav', 'nav-opened');
        });
    });
})();