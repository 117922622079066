/* ****************************************************************************************************************** **
 * Standard application util class.                                                                                   **
 *                                                                                                                    **
 * Date: 2016-01-18 01:01:17                                                                                          **
 * Author: Martin Urbánek <urbanek@p-dva.cz>                                                                          **
 * ****************************************************************************************************************** */

/* ################################################################################################################## */

(function () {
    /**
     * Application library.
     * 
     * @constructor
     */
    var Application = function () {};
    
    /**
     * Run app when document ready.
     * 
     * @param {function} app
     */
    Application.prototype.run = function (app) {
        var self = this;
        
        $(document).ready(function () {
            app.call(self);
        });
    };
    
    
    /**
     * Get parameter from URL.
     * 
     * @param {string} key
     * @param {*} defaultValue
     */
    Application.prototype.getParameter = function (key, defaultValue) {
        defaultValue = (typeof defaultValue === 'undefined') ? null : defaultValue;
        
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            
            if (decodeURIComponent(pair[0]) == key) {
                return decodeURIComponent(pair[1]);
            }
        }
        
        return defaultValue;
    };
    
    
    /**
     * Manage confirmation alert.
     * 
     * @param {string} cssClass
     */
    Application.prototype.manageConfirmations = function (cssClass) {
        $('.' + cssClass + '[data-confirmation]').click(function (event) {
            if (!confirm($(this).data('confirmation'))) {
                event.preventDefault();
                
                return false;
            }
            
            return true;
        });
    };
    
    
    /**
     * Manage pseudo-REST delete actions.
     * 
     * @param {string} cssClass
     */
    Application.prototype.manageDeleteActios = function (cssClass) {
        $('a.' + cssClass + '[data-method][data-csrf][href]').click(function (event) {
            event.preventDefault();
            
            confirmationMessage = $(this).data('confirmation');
            
            if (typeof confirmationMessage !== 'undefined' && confirmationMessage) {
                if (!confirm(confirmationMessage)) {
                    return false;
                }
            }
            
            var form = $('<form />').hide();
            
            form.attr({
                'action': $(this).attr('href'),
                'method': 'POST'
            });
            
            form.append($('<input type="hidden" name="_method" value="' + $(this).data('method') + '" />'));
            form.append($('<input type="hidden" name="_csrf" value="' + $(this).data('csrf') + '" />'));
            
            $(this).parent().append(form);
            
            form.submit();
            
            return false;
        });
    };
    
    
    /**
     * Manage GDS basic grid.
     */
    Application.prototype.gds = function () {
        var self = this;
        
        var options = {
            'orderDir': self.getParameter('orderDir', 'ASC'),
            'orderBy': self.getParameter('orderBy')
        };
        
        var swapSortOrder = function (order) {
            return (order === 'ASC') ? 'DESC' : 'ASC';
        };
        
        if (options.orderBy !== null) {
            var column = $('.gds-grid .gds-sortable[data-gds-name="' + options.orderBy + '"]');
            
            $(column).addClass('gds-active');
            
            if (options.orderDir === 'DESC') {
                $(column).addClass('gds-active-sort-desc');
            } else {
                $(column).addClass('gds-active-sort-asc');
            }
        }
        
        $('.gds-grid .gds-sortable').on('click', function () {
            var url = $(this).parents('.gds-grid').data('gds-url');
            var attrName = $(this).data('gds-name');
            
            if (options.orderBy === attrName) {
                options.orderDir = swapSortOrder(options.orderDir);
            } else {
                options.orderDir = 'ASC';
            }
            
            options.orderBy = attrName;
            
            $('.gds-grid .gds-sortable')
                .removeClass('gds-active')
                .removeClass('gds-active-sort-asc')
                .removeClass('gds-active-sort-desc');
            
            $(this).addClass('gds-active').addClass('gds-active-sort-' + options.orderDir.toLowerCase());
            
            url += '?orderBy=' + options.orderBy + '&orderDir=' + options.orderDir;
            
            window.location.href = url;
        });
        
        $('.gds-paginator-prev:not(.disabled), .gds-paginator-next:not(.disabled)').on('click', function () {
            var url = window.location.href;
            var page = $(this).data('gds-page');
            var pattern = /offset=\d+/;
            
            if (url.match(pattern)) {
                url = url.replace(pattern, 'offset=' + page);
            } else {
                if (window.location.search.substr(1).length === 0) {
                    if (window.location.href.match(/\?$/i) !== null) {
                        url += 'offset=' + page;
                    } else {
                        url += '?offset=' + page;
                    }
                } else {
                    url += '&offset=' + page;
                }
            }
            
            window.location = url;
        });
    };
    
    window.$Application = new Application();
})();

/* ################################################################################################################## */
